import Avatar from 'components/common/Avatar';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Flex, Link, Text } from '@chakra-ui/react';

import { User } from 'types';
import { clearSession } from 'utils/sessionStorage';

export const UserInfo = ({ userInfo }: { userInfo?: User }) => {
  const navigate = useNavigate();

  return (
    <Flex align="flex-start">
      <Avatar name={userInfo?.given_name} logo={userInfo?.picture} boxSize="40px" borderRadius="20px" size="sm" />

      <Box ml={3}>
        <Text fontWeight="bold">{userInfo?.given_name}</Text>
        <Text fontSize="sm">{userInfo?.email}</Text>
        <Link
          fontSize="sm"
          onClick={(e) => {
            clearSession();
            navigate('/');
          }}
        >
          Log out
        </Link>
      </Box>
    </Flex>
  );
};
