import { Divider, Heading, Icon, Image, VStack } from '@chakra-ui/react';

import Button from 'components/common/Button';
import React from 'react';
import { FaHome } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { User } from 'types';
import { ClientsList } from './sidebar/ClientsList';
import { UserInfo } from './sidebar/UserInfo';

interface SidebarProps {
  userInfo?: User;
}

const Sidebar = ({ userInfo }: SidebarProps) => {
  const navigate = useNavigate();

  return (
    <VStack p={8} spacing={4} align="stretch" bg="white">
      <Heading size="md" mb={2}>
        <Image src="/svgs/yai-logo.svg" alt="YAi" />
      </Heading>

      <VStack align="start">
        <Button variant="link" mt={4} onClick={() => navigate('/dashboard')} colorScheme="black">
          <Icon as={FaHome} boxSize="1em" color="black" mr="1em" cursor="pointer" onClick={() => navigate('/dashboard')} />
          Home
        </Button>

        <Divider my={4} />
        <ClientsList />
      </VStack>

      {/* Add other navigation sections */}
      <Divider my={4} />

      <UserInfo userInfo={userInfo} />
    </VStack>
  );
};

export default Sidebar;
