import { Box, ChakraProps, Flex } from '@chakra-ui/react';
import Sidebar from 'components/dashboard/Sidebar';
import React from 'react';

import { getUserSession } from 'utils/sessionStorage';

const MainLayout = ({ children, ...props }: React.PropsWithChildren & ChakraProps) => {
  const userInfo = getUserSession();
  return (
    <Flex h="100vh" overflow="hidden">
      {/* Sidebar */}
      <Box w={{ base: '30%', xl: '20%' }} bg="white">
        <Sidebar userInfo={userInfo} />
      </Box>

      {/* Main Content */}
      <Box w={{ base: '70%', xl: '80%' }} p={5} height="100vh" {...props}>
        {children}
      </Box>
    </Flex>
  );
};

export default MainLayout;
