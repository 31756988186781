import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { REACT_APP_BASE_URL } from 'utils/consts';
import { clearSession, getSessionType, getSessionid, getUserId } from 'utils/sessionStorage';

export const enum RtkTagTypes {
  'Clients' = 'Clients',
  'Projects' = 'Projects',
  'ProjectsSummary' = 'ProjectsSummary',
  'ProjectsTopics' = 'ProjectsTopics',
  'ProjectsOutline' = 'ProjectsOutline',
}

export const tagTypes = [
  RtkTagTypes.Clients,
  RtkTagTypes.Projects,
  RtkTagTypes.ProjectsSummary,
  RtkTagTypes.ProjectsTopics,
  RtkTagTypes.ProjectsOutline,
];

export const baseApi = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_BASE_URL,
    prepareHeaders: (headers, api) => {
      headers.set('session-id', getSessionid());
      headers.set('session-type', getSessionType());
      headers.set('user-id', getUserId());

      return headers;
    },
    validateStatus: (response, result) => {
      if (response.status === 401) {
        clearSession();
        // Redirect to login page if unauthorized
        window.location.href = '/';
      }
      // TODO: Handle other status codes
      return result;
    },
  }),
  tagTypes,
  /**
   * Endpoints are defined in the others services/api/*.ts file
   * We create an empty object here to avoid circular dependencies
   */
  endpoints: () => ({}),
});
